import React, { FC } from 'react';
import Form from '../Form';
import { Box } from '@rebass/grid';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { FormInterface as GeneratedFormInterface } from '../../generated/graphql';

interface RecaptchaIntegration {
    enabled: string;
    showAllPages?: string;
}

interface ExtendedFormSettings extends Omit<GeneratedFormInterface['settings'], 'integrations'> {
    integrations: {
        recaptcha?: RecaptchaIntegration;
    };
    submitActionFormHide: boolean;
    submitActionMessageHtml: string;
    submitAction: string;
    submitActionTab: string | null;
    redirectUrl: string;
}

export interface ExtendedFormInterface extends Omit<GeneratedFormInterface, 'settings'> {
    settings: ExtendedFormSettings;
}

interface Props {
    forms: ExtendedFormInterface[];
}

const FormBlock: FC<React.PropsWithChildren<Props>> = ({ forms }) => (
    <>
        {forms.map((form, i) => {
            const hasRecaptchaEnabled = form.settings?.integrations?.recaptcha?.enabled === '1';

            if (hasRecaptchaEnabled) {
                return (
                    <GoogleReCaptchaProvider key={form.id ?? i} reCaptchaKey="6LdwiogUAAAAAPD40Lc0AopJ2sYPER9WVGBW0_RY">
                        <Box mb={[3, 5]} mt={[4, 6]}>
                            <Form form={form} />
                        </Box>
                    </GoogleReCaptchaProvider>
                );
            }
            return (
                <Box key={form.id ?? i} mb={[3, 5]} mt={[4, 6]}>
                    <Form form={form} />
                </Box>
            );
        })}
    </>
);

export default FormBlock;
