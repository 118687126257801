const fetchFormData = async (blockId: string | null | undefined) => {
    const cmsUrl = process.env.NEXT_PUBLIC_CMS_URL;
    try {
        const response = await fetch(`${cmsUrl}/texel/form/${blockId}`);
        if (!response.ok) {
            throw new Error(`Error fetching form data: ${response.statusText}`);
        }
        return await response.json();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching form data:', error);
        throw error;
    }
};

export default fetchFormData;
